<template>
	<div class="page">
		<!-- 顶部菜单 -->
		<topBar :curIndex="2"></topBar>
		<div class="block20"></div>
		<div class="infomation_menu_box w1240">
			<div class="menuitem" :class="{'hover':menuTabCur==index}" v-for="(item,index) in menulist" :key="index" @click="handleMune(index)">
				{{item.title}}
			</div>
		</div>
		<div class="block20"></div>
		<div class="infomation_con_box w1240">
			<div class="itemobj" v-for="(item,index) in dataList" :key="index" @click="clicklearning(item.id)">
				<div class="imgdiv"><img :src="item.articlePic"/></div>
				<div class="conbox">
					<div class="titlebox">
						<div class="title clamp2">{{item.title}}</div>
						<div class="desc clamp2">{{item.body || ""}}</div>
					</div>
					<div class="timer">{{item.createDate}}</div>
				</div>
			</div>
		</div>
		<div class="pagination_box_white w1240">
		    <el-pagination
		          @current-change="handleCurrentChange"
		          :current-page.sync="currentPage"
		          :page-size="pageSize"
		          layout="prev, pager, next, jumper"
		          :total="total"
				  background>
		    </el-pagination>
		</div>
		<div class="block40"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
import  topBar  from "../components/topBar/topBar.vue";
export default {
	components: {
		topBar
	},
	data() {
		return {
			uid:"",
			menulist:[],
			menuTabCur:0,//下标
			dataList:[],
			currentPage:1,
			pageSize:10,
			total:0,
		};
	},
	mounted: function() {
		this.gettypePage()
	},
	computed: {
		...mapState(['userInfo', 'isLogin','bsnName']),
	},
	methods: {
		//跳转页面
		handleCurrentChange(currentPage){
			console.log(currentPage)
			this.currentPage = currentPage
			//请求文章
			this.getarticlePage()
		},
		//选择栏目
		handleMune(index){
			//console.log(index);
			this.menuTabCur = index
			//请求文章
			this.getarticlePage()
		},
		//获取栏目
		gettypePage() {
			var _this = this
			var params = {}
			params["currentPage"] = 1
			params["pageSize"] = 58
			this.$http.post('articleModule/article-type/getPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records
					var obj = {
						id:0,
						title:"全部"
					}
					records.unshift(obj)
					_this.menulist = records
					//请求文章
					_this.getarticlePage()
				}
			})
		},
		//列表
		getarticlePage() {
			var _this = this
			var params = {}
			params["moduleId"] = 2 //1:系统公告,2:资讯文章,3:关于我们
			params["currentPage"] = this.currentPage
			params["pageSize"] = this.pageSize
			params["articleTypeId"] = this.menulist[this.menuTabCur].id==0?null:this.menulist[this.menuTabCur].id
			this.$http.post('articleModule/article-info/getSecondPage', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.total =  res.data.total;
					if(records.length>0){
						records.forEach((item,index)=>{
							item["body"] = _this.$util.filtersText(item.articleContent,138)
						})
						_this.dataList = records
					}
				}
			})
		},
		//文章详情
		clicklearning(uid){
			this.$router.push({ path: '/infoDetail', query: {uid: uid}})
		},
		
	}
};
</script>
<style lang="scss" scoped></style>
